<template>
	<div class="">
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div v-else>
			<div class="main-content">
				<div class="header bg-white border-bottom">
					<div class="container-fluid">
						<div class="header-body border-0">
							<div class="row align-items-end">
								<div class="col">
									<h6 class="header-pretitle">Rentals Overview</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid">
					<router-link to="/charter/list" class="btn btn-light mb-4 btn-sm">
						Go back
					</router-link>
					<div class="row">
						<div class="col-12 col-xl-7">
							<div class="card">
								<div class="card-body p-2">
									<div>
										<div style="padding: 1rem 1.5rem">
											<h4>Customer Information</h4>
										</div>
										<div class="list-group list-group-flush mb-4">
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Full Name</span
															>
													</div>
													<div class="col-auto">
														<span class="">{{
															`${charter.fname} ${charter.lname}`
														}}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase">Email</span>
													</div>
													<div class="col-auto">
														<span class="">{{ charter.email }}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Phone Number</span
															>
													</div>
													<div class="col-auto">
														<span class="">{{ charter.phone }}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>How did you hear about us?</span
															>
													</div>
													<div class="col-auto">
														<span class="">{{ charter.how_did_you_hear }}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Vehicle</span
															>
													</div>
													<div class="col-auto">
														<span class="">{{ charter.vehicles }}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase">Date Created</span>
													</div>
													<div class="col-auto">
														<span>{{ charter.created_at | date('yyyy-MM-dd hh:mm a') }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div style="padding: 1rem 1.5rem">
											<h4>Pickup Information</h4>
										</div>
										<div class="list-group list-group-flush mb-4">
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Pickup Address</span
															>
													</div>
													<div class="col-auto">
														<span class="">{{ charter.pickup_address }}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Pickup Date</span
															>
													</div>
													<div class="col-auto">
														<span class=""
															>{{ charter.pickup_date }}
															{{ charter.pickup_time }}</span
															>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Drop Off Address</span
															>
													</div>
													<div class="col-auto">
														<span class="">{{ charter.dropoff_address }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div v-if="charter.return_date">
										<div style="padding: 1rem 1.5rem">
											<h4>Return Trip</h4>
										</div>
										<div class="list-group list-group-flush mb-4">
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Return Address</span
															>
													</div>
													<div class="col-auto">
														<span class="">{{ charter.return_address }}</span>
													</div>
												</div>
											</div>
											<div class="list-group-item">
												<div class="row align-items-center">
													<div class="col">
														<span class="text-muted text-uppercase"
															>Return Time</span
															>
													</div>
													<div class="col-auto">
														<span class="">{{ charter.return_date }} {{ charter.return_time }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-xl-5" v-if="charter.status !== 'Completed'">
							<div class="card">
								<div class="card-header">
									<div class="row align-items-center">
										<div class="col">
											<h4 class="card-header-title">Admin Feedback</h4>
										</div>
									</div>
								</div>
								<div class="card-body">
									<div
										v-if="fetchingDrivers"
										class="d-flex justify-content-center align-items-center"
										>
										<div class="spinner-border" role="status"></div>
									</div>
									<template v-else>
										<div
											class="alert alert-danger alert-dismissible fade show"
											role="alert"
											v-if="errorMessage"
											>
											{{ errorMessage }}
										</div>
										<form @submit.prevent="giveAdminFeedback">
											<div class="row">
												<div class="col-12 col-md-6">
													<div class="form-group">
														<label class="form-label">Set Status</label>
														<select
															class="form-select form-control mb-3"
															v-model.trim="adminFeedback.status"
															required
															>
															<option value="Accepted">Accepted</option>
															<option value="Completed">Completed</option>
															<option value="Cancelled">Cancelled</option>
														</select>
													</div>
												</div>
												<div class="col-12 col-md-6">
													<div class="form-group">
														<label class="form-label">Assign Driver</label>
														<v-select
															v-model="adminFeedback.driver"
															class="form-group"
															:options="drivers"
															label="fname"
															:filterBy="driverFilter"
															placeholder="Select a driver"
															required
															>
															<template v-slot:option="driver">
																{{ `${driver.fname} ${driver.lname}` }}
															</template>
															<template v-slot:selected-option="driver">
																{{ `${driver.fname} ${driver.lname}` }}
															</template>
														</v-select>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-12 col-md-6">
													<div class="form-group">
														<label class="form-label">Set Fare</label>
														<input
															v-model.trim="adminFeedback.fare"
															required
															type="number"
															class="form-control"
															placeholder="2000"
															/>
													</div>
												</div>
											</div>
											<button
												v-if="userHasPermission('charter005')"
												type="submit"
												class="btn btn-primary mt-4"
												:disabled="processing"
												>
												{{ processing ? 'Processing...' : 'Update Request' }}
											</button>
											<button
												v-else
												type="button"
												class="btn btn-primary mt-4"
												:disabled="true"
												>
												unauthorised
											</button>
										</form>
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import { filterDriverBy } from '@/utils/helpers'

export default {
  props: {
    charterId: {
      required: true,
      type: [Number, String]
    }
  },
  data () {
    return {
      charter: null,
      loading: true,
      errorLoading: false,
      error: null,
      adminFeedback: {
        status: '',
        driver: null,
        fare: ''
      },
      drivers: [],
      fetchingDrivers: false,
      processing: false,
      errorMessage: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    driverFilter (option, label, search) {
      return filterDriverBy(option, label, search)
    },
    async init () {
      this.loading = true
      this.errorLoading = false

      this.fetchCharter()
        .then(() => {
          this.adminFeedback = {
            status: this.charter.status,
            driver: this.charter.driver,
            fare: this.charter.fare
          }

          this.fetchDrivers()
        })
        .catch(() => (this.errorLoading = true))
        .finally(() => (this.loading = false))
    },
    fetchCharter () {
      return this.axios.get(`/v1/charters/${this.charterId}`).then((res) => {
        this.charter = res.data
      })
    },
    fetchDrivers () {
      this.fetchingDrivers = true

      this.axios
        .get('/v1/drivers')
        .then((res) => {
          this.drivers = res.data
        })
        .finally(() => (this.fetchingDrivers = false))
    },
    giveAdminFeedback () {
      this.processing = true

      this.errorMessage = ''

      this.axios({
        method: 'PATCH',
        url: `/v1/charters/${this.charterId}`,
        data: {
          driver_id: this.adminFeedback.driver.id,
          fare: this.adminFeedback.fare,
          status: this.adminFeedback.status
        }
      })
        .then((res) => {
          this.$swal({
            icon: 'success',
            title: 'Request updated',
            text: 'The charter request has been updated successfully',
            showCloseButton: true
          }).then(() => {
            this.charter = res.data
          })
        })
        .catch((e) => {
          this.errorProcessing = true

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg

          this.$swal({
            icon: 'error',
            text: 'An error occured, please try again',
            showCloseButton: true
          })
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>>
</style>
